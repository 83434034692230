<template>
  <b-card no-body>
    <b-card-header class="">
      <div class="d-flex justify-content-between w-100 flex-wrap align-items-center">
        <h4 class="font-weight-bold m-0">
          {{ $t('offer') }}
        </h4>
        <span class="text-primary font-weight-bold">
          {{ offerId }}
        </span>
        <b-badge
          v-if="getOfferInfo(offer).status.name"
          :variant="getOfferInfo(offer).status.variant"
          class="px-1"
        >
          {{ getOfferInfo(offer).status.name }}
        </b-badge>
      </div>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="8">
          <div class="text-right mb-1">
            {{ $t('countryAndCode') }}:
          </div>
        </b-col>
        <b-col cols="4">
          <div class="text-left mb-1">
            <template v-if="offer.country">
              {{ offer.country.name }} ({{ offer.country.code }})
            </template>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="8">
          <div class="text-right mb-1">
            {{ $t('validityPeriodOfTheOffer') }}:
          </div>
        </b-col>
        <b-col cols="4">
          <div class="text-left mb-1">
            {{ resolveDate(offer.valid_until) || '-' }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="8">
          <div class="text-right mb-1">
            {{ $t('leadTimeDays') }}:
          </div>
        </b-col>
        <b-col cols="4">
          <div class="text-left mb-1">
            {{ offer.lead_time || 0 }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="8">
          <div class="text-right">
            {{ $t('fullOfferCost') }}:
          </div>
        </b-col>
        <b-col cols="4">
          <div class="text-left font-weight-bold">
            {{ offer.currency | currencySymbol }}{{ offer.price || 0 }}
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BBadge, BRow, BCol,
} from 'bootstrap-vue';
import useOffersList from '@/views/trading/trading-offers/useOffersList';

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BBadge,
    BRow,
    BCol,
  },
  setup() {
    const {
      getOfferInfo,
      resolveDate,
    } = useOffersList();
    return {
      getOfferInfo,
      resolveDate,
    };
  },
  props: {
    offer: {
      type: Object,
      default: (() => {}),
    },
    offerId: {
      type: String,
      default: '',
    },
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
@import 'src/@core/scss/base/core/colors/palette-variables.scss';

.company-card-header {
  text-transform: uppercase;
}

.company-card-country {
  font-size: 12px;
}
</style>
