import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';
import i18n from '@/libs/i18n';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { resolveDate } from '@/utils/dateUtils.js';
import { getOfferInfo, resolveOfferStatusVariantAndIcon } from '@/utils/offerUtils.js';

export default function useOffersList() {
  // Use toast
  const toast = useToast();

  const refOfferListTable = ref(null);

  // Table Handlers
  const tableColumns = computed(() => {
    const columns = [
      { key: 'id', label: '#', sortable: true },
      { key: 'date', label: i18n.t('dateTerm'), sortable: true },
      { key: 'supplier', label: i18n.t('supplier') },
      { key: 'product', label: i18n.t('product') },
      { key: 'price', label: i18n.t('price'), sortable: true },
      { key: 'buyer', label: i18n.t('buyer') },
      { key: 'status', label: i18n.t('status'), sortable: true },
      { key: 'actions', label: i18n.t('actions') },
    ];

    if (store.getters['profile/isAdmin']) {
      return columns;
    }

    return columns.filter(column => !['supplier', 'buyer'].includes(column.key));
  });
  const perPage = ref(10);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const sortBy = ref('id');
  const isSortDirDesc = ref(true);

  const supplierFilter = ref([]);
  const supplierCountriesFilter = ref([]);
  const statusFilter = ref([]);
  const verifiedFilter = ref(null);

  const items = computed(() => store.state.offers.ownOffers);
  const totalOffers = computed(() => store.state.offers.ownMeta.total);
  const isAdmin = computed(() => store.getters['profile/isAdmin']);
  const dataMeta = computed(() => {
    const localItemsCount = refOfferListTable.value ? refOfferListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalOffers.value,
    };
  });

  const fetchOffers = async () => {
    try {
      await store.dispatch('offers/fetchOffers', {
        fetchParam: isAdmin.value ? 'all' : 'own',
        data: {
          per_page: perPage.value,
          page: currentPage.value,
          owned_by_legal_ids: supplierFilter.value.map(({ id }) => id),
          owned_by_country_ids: supplierCountriesFilter.value.map(({ id }) => id),
          statuses: statusFilter.value.map(({ value }) => value),
          is_verified: verifiedFilter.value?.value,
        },
      });
    } catch (err) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching offers list',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      });
    }
  };

  const refetchData = async () => {
    if (refOfferListTable.value) {
      refOfferListTable.value.refresh();
    }
    await fetchOffers();
  };

  watch([currentPage, perPage, supplierFilter, supplierCountriesFilter, statusFilter, verifiedFilter], async () => {
    await refetchData();
  });

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveClientAvatarVariant = status => {
    if (status === 'Partial Payment') return 'primary';
    if (status === 'Paid') return 'danger';
    if (status === 'Downloaded') return 'secondary';
    if (status === 'Draft') return 'warning';
    if (status === 'Sent') return 'info';
    if (status === 'Past Due') return 'success';
    return 'primary';
  };

  const toString = value => {
    if (value === null || typeof value === 'undefined') {
      return '';
    } if (value instanceof Object) {
      return Object.keys(value)
        .sort()
        .map(key => toString(value[key]))
        .join(' ');
    }
    return String(value);
  };

  const sortCompare = (aRow, bRow, key, sortDesc, formatter, compareOptions, compareLocale) => {
    if (key === 'date') {
      return aRow.created_at < bRow.created_at ? -1 : aRow.created_at > bRow.created_at ? 1 : 0;
    }
    const a = aRow[key];
    const b = bRow[key];
    if (
      (typeof a === 'number' && typeof b === 'number')
      || (a instanceof Date && b instanceof Date)
    ) {
      return a < b ? -1 : a > b ? 1 : 0;
    }
    return toString(a).localeCompare(toString(b), compareLocale, compareOptions);
  };

  return {
    fetchOffers,
    tableColumns,
    perPage,
    items,
    currentPage,
    totalOffers,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refOfferListTable,

    supplierFilter,
    supplierCountriesFilter,
    statusFilter,
    verifiedFilter,

    getOfferInfo,
    resolveDate,
    resolveOfferStatusVariantAndIcon,
    resolveClientAvatarVariant,

    sortCompare,
    refetchData,
  };
}
