<template>
  <section class="offer-add-wrapper">
    <b-row
      class="offer-add"
    >
      <!-- Col: Left (Offer Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <trading-products
          :products="offer.products || []"
          :currency="offer.currency"
          :margin="offer.margin"
          :title="$t('products')"
          :is-offer-editable="isOfferEditable"
          @update-products="updateProducts"
          @update-start="updateProducts"
        />
        <trading-deal-comments
          v-if="isUserAdminAndIsEditMode"
          :title="$t('comments')"
          :comment-owner="offer.last_comment || {}"
          :comment-request="noteRequest"
        />
        <trading-offer-transactions
          v-if="isUserAdminAndIsEditMode && !!offer.request"
          :title="$t('documentsAndPayments')"
          :offer="offer"
        />
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
      >
        <trading-offer-preview-deal
          :offer="offer"
          :offer-id="offerId"
        />
        <!--        <trading-new-validity-period-->
        <!--            title="Validity period of the offer"-->
        <!--            :date="offer.valid_until"-->
        <!--            @update="updateValidityTerm"-->
        <!--        />-->
        <!--        <trading-new-save-aside-->
        <!--            :full-cost="getFullPrice"-->
        <!--            :message.sync="offer.note"-->
        <!--            :is-offer="true"-->
        <!--            :is-save-disabled="offer.products.length < 1"-->
        <!--            @save="save"-->
        <!--            @publish="publish"-->
        <!--            @update="setNote"-->
        <!--            @cancel="cancel"-->
        <!--        />-->
        <TradingOfferVerification
          v-if="isUserAdminAndIsEditMode && !offer.request"
          :currency="offer.currency"
          :offer-price="offer.real_price"
          :verified.sync="offer.status"
          :default-margin.sync="offer.margin"
          @change-price="offer.price = $event"
        />

        <b-card v-if="isAllowBuy">
          <b-button
            class="w-100"
            variant="primary"
            @click="buyOffer"
          >
            {{ $t('buy') }}
          </b-button>
        </b-card>
        <b-card v-if="!isBuyer">
          <template v-if="offer.status === 2">
            <b-button
              variant="primary"
              class="btn-cart w-100"
              :to="{ name: 'trading-deals-list', params: { param: 'all' } }"
            >
              <span>{{ $t('goToDeal') }}</span>
            </b-button>
          </template>
          <template v-else>
            <b-tooltip
              v-if="!isOfferRemovable"
              :title="$t('offerUsedInDealOrRequest')"
              target="editButton"
              triggers="hover"
            />
            <div
              id="editButton"
              class="button-div mt-1"
            >
              <b-button
                variant="primary"
                class="btn-cart w-100"
                :disabled="!isOfferEditable"
                :to="{ name: 'trading-offers-add', params: { id: offer.id } }"
              >
                <span>{{ $t('edit') }}</span>
              </b-button>
            </div>
          </template>

          <b-tooltip
            v-if="!isOfferRemovable"
            :title="$t('offerUsedInDealOrRequest')"
            target="deleteButton"
            triggers="hover"
          />
          <div
            id="deleteButton"
            class="button-div mt-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="!isOfferRemovable"
              variant="outline-danger"
              @click="handleDeleteOfferClick"
            >
              {{ $t('deleteOffer') }}
            </b-button>
          </div>
        </b-card>

        <b-card>
          <b-button
            class="w-100"
            variant="outline-secondary"
            :to="{ name: 'trading-open-offers-list', params: { param: isSupplierOnly ? 'own' : 'open' } }"
          >
            {{ $t('backToHotSales') }}
          </b-button>
        </b-card>
      </b-col>
    </b-row>
    <BuyOfferModal
      :id="offer.id"
      v-model="showModal"
    />

    <confirm-modal
      :modal-visible="deleteModalVisible"
      :modal-message="String($t('deleteOfferConfirmMessage', {id: currentOffer.id}))"
      :ok-text="String($t('yesDelete'))"
      :cancel-text="String($t('noCancel'))"
      :is-ok-button-spinning="isOkButtonSpinning"
      @modal-hidden="deleteModalVisible = false"
      @yes-clicked="deleteOffer"
      @no-clicked="deleteModalVisible = false"
    />
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BButton, BTooltip,
} from 'bootstrap-vue';
import TradingDealComments from '@/views/trading/trading-deals/TradingDealComments.vue';
import TradingProducts from '@/views/trading/TradingProducts.vue';
import TradingOfferPreviewDeal from '@/views/trading/trading-offers/TradingOfferPreviewDeal.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import Ripple from 'vue-ripple-directive';
import useOffersList from '@/views/trading/trading-offers/useOffersList';
import TradingOfferVerification from '@/views/trading/trading-offers/TradingOfferVerification.vue';
import BuyOfferModal from '@/views/apps/components/modals/BuyOfferModal.vue';
import TradingOfferTransactions from '@/views/trading/trading-offers/transactions/TradingOfferTransactions.vue';
import confirmModal from '@/views/apps/components/modals/confirmModal.vue';
import axios from '@/libs/axios';
import router from '@/router';

export default {
  components: {
    confirmModal,
    BTooltip,
    TradingOfferVerification,
    BCard,
    BRow,
    BCol,
    BButton,
    TradingProducts,
    BuyOfferModal,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    TradingOfferPreviewDeal,
    TradingDealComments,
    TradingOfferTransactions,
  },
  directives: {
    Ripple,
  },

  data: () => ({
    offer: {
      id: null,
      created_by: null,
      owned_by: null,
      price: '0.00',
      real_price: 0,
      currency: 1,
      packing: null,
      valid_until: '',
      note: '',
      status: 0,
      verified: null,
      last_comment: null,
      created_at: null,
      updated_at: null,
      products: [],
      country_id: '',
    },
    editVerify: false,
    offerId: '',
    showModal: null,
    deleteModalVisible: false,
    isOkButtonSpinning: false,
  }),
  setup() {
    const {
      resolveOfferStatusVariantAndIcon,
    } = useOffersList();

    return {
      resolveOfferStatusVariantAndIcon,
    };
  },

  computed: {
    noteRequest() {
      const isHaveRequest = !!this.$store.state.offers.currentOffer;
      let note = {};
      if (isHaveRequest) {
        note = {
          // note: this.$store.state?.deal.deal?.request?.note,
          note: this.$store.state?.offers?.currentOffer?.note,
          user: {
            ...this.$store.state?.offers?.currentOffer?.owned_by?.profile,
            company_name: this.$store.state?.offers?.currentOffer?.owned_by?.company_name,
            date: this.$store.state?.offers?.currentOffer?.created_at,
            role: 'supplier',
          },
        };
      }
      return note;
    },
    isVerifiedOffer() {
      return this.$store.state.offers.currentOffer?.status === 4;
    },
    isAllowBuy() {
      return this.isVerifiedOffer
        && this.isBuyer
        && this.$store.state.offers.currentOffer?.owned_by?.id !== this.$store.getters['profile/profile']?.id;
    },
    isBuyer() {
      return this.$store.getters['profile/isBuyerOnly'];
    },
    isSupplierOnly() {
      return this.$store.getters['profile/isSupplierOnly'];
    },
    margin: {
      get() {
        return this.currentOffer?.margin;
      },
      set(val) {
        this.marginData = val;
      },
    },
    isUserAdminAndIsEditMode() {
      return this.$store.getters['profile/isAdmin'] && this.$route.params.id !== 'new';
    },
    dataToSend() {
      return {
        currency: this.offer.currency,
        note: this.offer.note,
        product_ids: this.offer.products.map(item => item.id),
        country_id: this.offer.country_id,
        valid_until: this.offer.valid_until,
      };
    },
    currentOffer() {
      return this.$store.state.offers.currentOffer;
    },
    isOfferRemovable() {
      return this.currentOffer?.can_delete;
    },
    isOfferEditable() {
      return this.currentOffer?.can_edit;
    },
  },
  watch: {
    currentOffer: {
      handler(val) {
        this.offer = {
          ...this.offer,
          ...val,
          country_id: this.offer.country_id || val?.country?.id || '',
        };
      },
      deep: true,
    },
  },
  async created() {
    try {
      if (this.$route.params.id && this.$route.params.id !== 'new') {
        await this.$store.dispatch('offers/fetchLegalOptions');
        await this.$store.dispatch('offers/fetchOfferById', {
          offerId: this.$route.params.id,
          isAdmin: this.$store.getters['profile/isAdmin'],
        });
        this.offer = {
          ...this.offer,
          ...this.$store.state.offers.currentOffer,
          country_id: this.$store.state.offers.currentOffer?.country?.id || '',
        };
        this.offerId = `#${this.offer.id}`;
      }
      if (this.$route.params.id && this.$route.params.id === 'new') {
        this.offerId = this.$t('new');
      }
    } catch {
      this.$router.go(-1);
    }
  },
  mounted() {
    this.editVerify = this.isVerifiedOffer || this.offer.status === 1;
  },
  methods: {
    async buyOffer() {
      this.showModal = this.offer.id;
    },
    setNote(data) {
      this.offer.note = data;
    },
    updateValidityTerm(date) {
      this.offer.valid_until = date;
    },
    updateProducts(data) {
      this.offer.products = data;
    },
    async save() {
      if (this.$route.params.id === 'new' && this.offer.products.length) {
        const { data } = await this.$http.post('/offers', this.dataToSend);
        if (data.status) {
          await this.$router.push({ name: this.$router.currentRoute.name, params: { id: data.data.offer.id } });
          this.setSystemNotification(this.$t('changesSaved'), 'CheckCircleIcon', 'success');
        } else {
          this.setSystemNotification(data.error, 'AlertTriangleIcon', 'danger');
        }
      } else {
        const { data } = await this.$http.post(`/offers/update/${this.$route.params.id}`, this.dataToSend);
        if (data.status) {
          this.setSystemNotification(this.$t('changesSaved'), 'CheckCircleIcon', 'success');
        }
      }
    },
    setSystemNotification(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      });
    },
    async publish() {
      await this.save();
      await this.$http.post(`/offers/publish/${this.$route.params.id}`, this.dataToSend);
    },
    cancel() {
      this.offer = { ...this.$store.state.offers.currentOffer };
    },
    handleDeleteOfferClick() {
      this.deleteModalVisible = true;
    },
    async deleteOffer() {
      this.isOkButtonSpinning = true;

      await axios.delete(`/v1/offers/${this.currentOffer.id}/delete`).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('offerDeletedSuccessfully', { id: this.currentOffer.id }),
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        });

        if (this.isAdmin) router.push({ name: 'trading-offers-list' });
        if (this.isSupplierOnly) router.push({ name: 'trading-open-offers-list', params: { param: 'own' } });

        this.modalVisible = false;
      }).catch(e => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('somethingWentWrong', { msg: e.response.data.message }),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }).finally(() => {
        this.isOkButtonSpinning = false;
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';

.button-div {
  width: 100%;

  button {
    width: 100%;
  }
}
</style>
