<template>
  <b-modal
    id="modal-center"
    v-model="open"
    centered
    title="Alert"
  >
    <p class="my-4">
      Do you want to buy it?
    </p>
    <div v-if="isManager">
      <b-form-group
        label="Select Buyer"
        label-for="buyer-select"
        class="mb-0"
      >
        <v-select
          v-model="buyerId"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :clearable="false"
          transition=""
          label="company_name"
          :options="$store.state.offers.legalBuyers"
          :reduce="option => option.id"
          input-id="buyer-select"
        />
      </b-form-group>
    </div>
    <template #modal-footer="{ cancel }">
      <b-button
        variant="secondary"
        @click="cancel"
      >
        {{ $t('cancel') }}
      </b-button>
      <b-button
        variant="primary"
        class="btn-cart"
        @click="approve"
      >
        <feather-icon
          icon="ShoppingCartIcon"
          class="mr-50"
        />
        <span>{{ $t('buy') }}</span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import store from '@/store/index.js';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import router from '@/router/index.js';
import { BButton, BModal, BFormGroup } from 'bootstrap-vue';
import vSelect from 'vue-select';

export default {
  name: 'BuyOfferModal',
  components: {
    BButton,
    BModal,
    BFormGroup,
    vSelect,
  },
  props: {
    value: {
      type: [Number, null],
    },
    id: {
      type: [Number, null],
    },
  },
  data() {
    return {
      buyerId: null,
    };
  },
  computed: {
    open: {
      get() {
        return !!this.value;
      },
      set(val) {
        this.$emit('input', val || null);
      },
    },
    isManager() {
      return this.$store.getters['profile/isAdmin'];
    },
  },
  watch: {
    isManager: {
      immediate: true,
      handler() {
        if (this.isManager) {
          this.buyerId = this.$store.state.profile.profile.company_id;
          this.$store.dispatch('offers/fetchLegalBuyers');
        }
      },
    },
    open() {
      if (this.isManager) {
        this.buyerId = this.$store.state.profile.profile.company_id;
      }
    },
  },
  methods: {
    async approve() {
      try {
        const id = await store.dispatch('offers/acceptOffer', { offerId: this.id, buyerId: this.buyerId });

        await router.push({ name: 'trading-deals-add', params: { id } });

        this.open = false;
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import '../../../../../node_modules/vue-select/dist/vue-select.css';
</style>
