<template>
  <b-card
    no-body
    :class="{'open': visible}"
    class="collapse-icon"
  >
    <b-card-header
      :class="{'collapsed': !visible}"
      :aria-expanded="visible ? 'true' : 'false'"
      aria-controls="products-collapse"
      role="tab"
      data-toggle="collapse"
      @click="updateVisible(!visible)"
    >
      <h4 class="d-inline-block mr-1 mb-0">
        {{ title }}
      </h4>
    </b-card-header>
    <b-collapse
      id="products-collapse"
      v-model="visible"
      role="tabpanel"
      accordion
    >
      <hr class="divider m-0" />
      <b-card-body>
        <trading-products-table
          :products="products"
          :currency="currency"
          :margin="margin"
          :allow-to-edit-products="isAllowToEditProducts"
          @update-products="removeProduct"
        />
        <b-pagination
          hide-goto-end-buttons
        />
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>

import {
  BCard, BPagination, BCardBody, BCardHeader, BCollapse,
} from 'bootstrap-vue';
import TradingProductsTable from '@/views/trading/TradingProductsTable.vue';

export default {
  components: {
    TradingProductsTable,
    BCard,
    BPagination,
    BCardBody,
    BCardHeader,
    BCollapse,
  },
  props: {
    isOfferEditable: {
      type: Boolean,
      default: true,
    },
    products: {
      default: (() => []),
      required: false,
      type: Array,
    },
    currency: {
      type: Number,
      default: 0,
    },
    title: {},
    margin: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      visible: true,
    };
  },
  computed: {
    isAllowToEditProducts() {
      return !this.$store.getters['profile/isBuyerOnly'] && this.isOfferEditable;
    },
  },
  methods: {
    updateVisible(val = true) {
      this.visible = val;
      this.$emit('visible', val);
    },
    removeProduct(product) {
      this.$emit('update-products', product);
    },
    editProduct(id) {
      // this.$emit('update-start', id);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
