<template>
  <b-card
    no-body
    :class="{'open': visible}"
    class="collapse-icon"
  >
    <b-card-header
      :class="{'collapsed': !visible}"
      :aria-expanded="visible ? 'true' : 'false'"
      aria-controls="comments-collapse"
      role="tab"
      data-toggle="collapse"
      @click="updateVisible(!visible)"
    >
      <h4 class="d-inline-block mr-1 mb-0">
        {{ title }}
      </h4>
    </b-card-header>
    <b-collapse
      id="comments-collapse"
      v-model="visible"
      role="tabpanel"
      accordion
    >
      <hr class="divider m-0" />
      <b-card-body>
        <app-timeline v-if="Object.keys(commentOwner).length || Object.keys(commentRequest).length">
          <app-timeline-item v-if="Object.keys(commentOwner).length">
            <b-row>
              <b-col cols="10">
                <p>{{ commentOwner.note }}</p>
              </b-col>
              <b-col
                cols="2"
                class="d-flex justify-content-end"
              >
                <small class="text-muted">
                  {{ commentOwner.date }}
                </small>
              </b-col>
            </b-row>
            <div class="d-flex flex-sm-row flex-column justify-content-between align-items-start">
              <b-media>
                <template #aside>
                  <b-avatar :src="commentOwner.user.avatar_url" />
                </template>
                <h6>{{ commentOwner.user.company_name }}</h6>
                <span class="text-muted">
                  {{ $t(commentOwner.user.role) }}
                </span>
              </b-media>
            </div>
          </app-timeline-item>
          <app-timeline-item v-if="Object.keys(commentRequest).length">
            <b-row>
              <b-col cols="10">
                <p>{{ commentRequest.note }}</p>
              </b-col>
              <b-col
                cols="2"
                class="d-flex justify-content-end"
              >
                <small class="text-muted">
                  {{ commentRequest.date }}
                </small>
              </b-col>
            </b-row>
            <div class="d-flex flex-sm-row flex-column justify-content-between align-items-start">
              <b-media>
                <template #aside>
                  <b-avatar :src="commentRequest.user.avatar_url" />
                </template>
                <h6>{{ commentRequest.user.company_name }}</h6>
                <span class="text-muted">
                  {{ $t(commentRequest.user.role) }}
                </span>
              </b-media>
            </div>
          </app-timeline-item>
        </app-timeline>
        <h4 v-else>
          {{ $t('noMessagesYet') }}
        </h4>
        <!--        <b-form-textarea-->
        <!--          class="mt-2"-->
        <!--          placeholder="Type your message here..."-->
        <!--        />-->
        <!--        <b-button-->
        <!--          class="mt-2"-->
        <!--          variant="primary"-->
        <!--        >-->
        <!--          Send message-->
        <!--        </b-button>-->
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import {
  BAvatar, BMedia, BCollapse,
  BCard, BCardBody, BCardHeader, BRow, BCol,
} from 'bootstrap-vue';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BMedia,
    BAvatar,
    BCollapse,
    BCard,
    BCardBody,
    BCardHeader,
    BRow,
    BCol,
  },
  props: {
    title: {},
    isDeal: {
      type: Boolean,
      default: false,
    },
    commentOwner: {
      type: Object,
      default: () => {},
    },
    commentRequest: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      visible: true,
    };
  },
  created() {
    const statusesToShow = [7, 8];
    const { deal } = this.$store.state.deal;
    if (this.isDeal && !statusesToShow.includes(deal.status)) {
      this.updateVisible(false);
    }
  },
  methods: {
    updateVisible(val = true) {
      this.visible = val;
    },
  },
};
</script>
